import React, { useState, useEffect } from 'react';
import rightImage from '../images/right.png';
import leftImage from '../images/left.png';
import Features from './Features';
import { FaCopy } from 'react-icons/fa';

const catchphrases = [
    'Account Control Panel',
    'Donate Panel',
    'L2 Store Panel',
];

const HomePage = ({ darkMode }) => {
    const [currentCatchphrase, setCurrentCatchphrase] = useState('');
    const [index, setIndex] = useState(0);
    const [typing, setTyping] = useState(true);
    const [charIndex, setCharIndex] = useState(0);
    const [copySuccess, setCopySuccess] = useState('');

    useEffect(() => {
        const interval = setInterval(() => {
            if (typing) {
                setCurrentCatchphrase((prev) => prev + catchphrases[index][charIndex]);
                setCharIndex((prev) => prev + 1);
                if (charIndex === catchphrases[index].length - 1) {
                    setTyping(false);
                    setTimeout(() => {
                        setCharIndex(0);
                        setIndex((prev) => (prev + 1) % catchphrases.length);
                    }, 2000);
                }
            } else {
                setCurrentCatchphrase((prev) => prev.slice(0, -1));
                if (currentCatchphrase === '') {
                    setTyping(true);
                }
            }
        }, 150);

        return () => clearInterval(interval);
    }, [currentCatchphrase, index, charIndex, typing]);



    const copyText = async () => {
        try {
            await navigator.clipboard.writeText('Giannis#2895');
            setCopySuccess('Copied!');
            setTimeout(() => {
                setCopySuccess('');
            }, 2000);
        } catch (err) {
            setCopySuccess('Failed to copy text.');
        }
    };


    return (
        <div
            className={`min-h-screen grid place-items-center ${darkMode ? "bg-gray-800" : "bg-l2j-text"
                }`}
        >
            <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden pointer-events-none">
                <div
                    className="hidden md:block absolute top-1/2 left-20 w-[25%] h-auto -translate-y-1/2 left-image">
                    <img
                        src={leftImage}
                        alt="Left floating image"
                        className="w-full h-auto"
                    />
                </div>
                <div
                    className="hidden md:block absolute top-1/2 right-20 w-[25%] h-auto -translate-y-1/2 right-image">
                    <img
                        src={rightImage}
                        alt="Right floating image"
                        className="w-full h-auto"
                    />
                </div>
            </div>
            <div className='w-full pt-20'>
                <h1 className="text-6xl font-bold text-gray-900 dark:text-gray-100 py-4 text-center">
                    L2jWeb
                </h1>
                <h2 className="text-2xl text-gray-700 dark:text-gray-200 text-center">
                    is an {currentCatchphrase}<span className="pipe"></span>
                </h2>
                <div className="flex justify-center items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-discord fill-gray-800 dark:fill-blue-500" viewBox="0 0 16 16">
                        <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
                    </svg>
                    <p className="text-gray-700 dark:text-gray-200 py-2 ml-4">Giannis#2895</p>
                    <button onClick={copyText} className="py-2 px-4 text-white rounded">
                        <FaCopy className='text-gray-800 dark:text-white' />
                    </button>
                </div>
                <div className="text-center">
                {copySuccess && <p className="text-gray-700 dark:text-gray-200">{copySuccess}</p>}
                </div>
            </div>
            <Features />
        </div>
    );
};

export default HomePage;
