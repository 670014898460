import React, { useState, useContext } from 'react';
import { FaCheck } from 'react-icons/fa';
import { FaInfoCircle, FaListUl } from 'react-icons/fa';

const Features = () => {
    const [activeFeature, setActiveFeature] = useState(1);
    const featureContent = () => {
        switch (activeFeature) {
            case 1:
                return (
                    <p className='text-justify text-white dark:text-gray-800'>
                        L2jWeb is a game panel for the popular MMORPG Lineage 2,
                        created and developed with care by a team of dedicated enthusiasts.
                        It provides a comprehensive set of tools and features for managing Lineage 2 game servers,
                        including player management, server configuration, and monitoring.
                    </p>

                );
            case 2:
                return (
                    <div className={`text-white dark:text-gray-800`}>
                        <ul className="list-disc list-inside mb-4">
                        <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                L2 Store with Admin CMS
                            </li>
                            <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                Stipe and Paypal payment methods
                            </li>
                            <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                Any type of game server and build
                            </li>
                            <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                Multifunctional User Control Panel
                            </li>
                            <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                Admin panel and CMS
                            </li>
                            <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                Safety &amp; Security
                            </li>
                            <li className="flex items-start">
                                <span className="inline-flex items-center justify-center h-5 w-5 mr-2 rounded-full bg-green-500">
                                    <FaCheck className="text-white" />
                                </span>
                                Unlimited warranty and support
                            </li>
                        </ul>
                    </div>
                );
            default:
                return null;


        }
    };
    return (
        <div className="pb-40 px-4">
            <div className="bg-gray-800 dark:bg-white w-96 min-h-[20rem] rounded-lg shadow-md p-8">
                <nav className="mb-6">
                    <ul className="flex justify-around">
                        <li className={`text-white dark:text-gray-800 font-semibold cursor-pointer py-2 px-4 ${activeFeature === 1 ? 'underline' : ''}`} onClick={() => setActiveFeature(1)}>
                            <FaInfoCircle className="inline-block mr-2" />
                            What's l2jWeb
                        </li>
                        <li className={`text-white dark:text-gray-800 font-semibold cursor-pointer py-2 px-4 ${activeFeature === 2 ? 'underline' : ''}`} onClick={() => setActiveFeature(2)}>
                            <FaListUl className="inline-block mr-2" />
                            Features
                        </li>
                    </ul>
                </nav>

                <div>
                    {featureContent()}
                </div>
            </div>
        </div>
    );
};

export default Features;






