import React, { useState } from 'react';
import Navbar from './components/Navbar';
import HomePage from './components/HomePage';
import Features from './components/Features';

function App() {
  const [darkMode, setDarkMode] = useState(false);

  return (
    <div className="App">
      <Navbar darkMode={darkMode} setDarkMode={setDarkMode} />
      <HomePage darkMode={darkMode} />
    </div>
  );
}

export default App;
